<template>
  <div>
    <b-row>
      <b-col class="col-md-6 col-12">
        <Breadcrumbs main="" title="Service Provider" class="showBreadcrumbs" />
        <!-- <Breadcrumbs main="" title="Service Provider" :display="isBreadView" /> -->
      </b-col>
    </b-row>
    <b-row class="">
      <b-col xl="12" md="">
        <b-card title="" type="gradient-red" sub-title="" class="mb-4">
          <b-row class="align-items-start">
            <b-col class="col-md-3 col-12">
              <b-form-group label="Provider Name">
                <multiselect
                  :options="providerList"
                  placeholder="Select Service Provider"
                  label="name"
                  v-model="selectedProvider"
                  :multiple="false"
                  :close-on-select="true"
                  track-by="_id"
                  @input="filterData"
                ></multiselect>
              </b-form-group>
            </b-col>

            <b-col class="d-flex mt-4"> </b-col>
          </b-row>

          <b-row class="align-items-start">
            <b-col class="col-md-6 col-12">
              <span class="custome-title">Service Provider</span>
            </b-col>

            <b-col
              class="col-md-6 col-12 d-flex justify-content-end align-items-start"
            >
              <b-form-group
                label-cols="7"
                label="Per page"
                class="col-6 md-0 custom-font-18 text-right"
              >
                <b-form-select
                  class="border custom-font-24"
                  v-model="perPage"
                  :options="pageOptions"
                  @change="handlePerPageChange"
                >
                </b-form-select>
              </b-form-group>
              <button class="btn custom-btn" @click="addProvider()">
                Add Provider
              </button>
            </b-col>
          </b-row>

          <div class="col-md-12" v-if="loading">
            <h6 class="sub-title mb-0 text-center">Loading...</h6>
            <div class="loader-box">
              <div class="loader-30"></div>
            </div>
          </div>
          <div v-else class="table-responsive datatable-vue cust-table-left">
            

            <b-table
              class="text-center custom-font-16"
              striped
              hover
              :items="spData"
              stacked="md"
              :busy="loading"
              :fields="spFields"
              :fixed="true"
            >
              <template #cell(name)="row">
                <div class="text-left">
                  <span>{{ sentenceCase(row.item.name) }}</span>
                </div>
              </template>

              <template #cell(status)="row">
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      :checked="row.item.status"
                      @change="changeStatus(row.item)"
                    />
                    <span
                      class="switch-state"
                      :class="row.item.status ? 'bg-success' : 'bg-danger'"
                    ></span>
                  </label>
                </div>
              </template>
              <template #cell(actions)="row">
                <button
                  size="sm"
                  @click="openSPDetail(row.item)"
                  class="btn text-blue btn-xs mr-1"
                >
                  <feather type="edit"></feather>
                </button>
                <button
                  size="sm"
                  @click="deleteSPDetail(row.item)"
                  class="btn text-danger btn-xs"
                >
                  <feather type="trash-2"></feather>
                </button>
              </template>
            </b-table>
          </div>
          <b-col class="mt-3 p-0">
            <b-col md="12" class="mt-3 text-right p-0">
             

              <div class="pagination">
                  <a 
                    v-show="currentPage > 1" 
                    @click="changePage(currentPage - 1)"
                    :disabled="loading"
                  >« Back</a>
                  <a class="active">{{ currentPage }}</a>
                  <a 
                    v-show="currentPage < totalPages" 
                    @click="changePage(currentPage + 1)"
                    :disabled="loading"
                  >Next »</a>
              </div>
            </b-col>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
    <!-- Service provider Modal -->
    <b-modal
      v-model="spModal"
      id="openSPDetail"
      title="Service Provider"
      @hidden="closeModal"
      class="theme-modal"
      size="xl"
      no-close-on-backdrop
      hide-footer
    >
      <spdetail
        :spdetail="spdetail"
        :spModal="spModal"
        :isEdit="isEdit"
        @closeSPDetail="closeModal"
      ></spdetail>
    </b-modal>
    <b-container fluid class="mt--7">
      <b-row class="justify-content-center">
        <b-col lg="12"> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import SPService from "../../../../services/ServiceProviderServices";
import spdetail from "./AddServiceProvider.vue";
import Multiselect from "vue-multiselect";

export default {
  components: {
    spdetail,
    Multiselect,
  },
  mounted() {
    this.userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
    // if (this.userinfo.role != "manager") {
    //   this.$router.push("/auth/login");
    // }
    this.init();
  },
  data() {
    return {
      spModal: false,
      spFields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      spData: [],
      providerList: [],
      userinfo: {},
      spdetail: {},
      isEdit: false,
      loading: false,
      totalRows: 0,
      totalPages: 0, // Added
      filter: {},
      selectedProvider: [],
      currentPage: 1,
      perPage: 20,
      pageOptions: [1,2,3,4,5,10,20, 50, 100, 200],
    };
  },
  watch: {
    spModal: function (val) {
      if (val == false) {
        this.init();
      }
    },
  },
  methods: {
    


    async filterData() {
      if (!this.selectedProvider || !this.selectedProvider.name) {
        await this.getServiceProvider();
        return;
      }
      this.spData = this.providerList.filter(
        (sp) => sp.name === this.selectedProvider.name
      );
      this.totalRows = this.spData.length;
      this.totalPages = Math.ceil(this.totalRows / this.perPage);
    },

    


    async getServiceProvider() {
      this.loading = true;
      try {
        const payload = {
          page: this.currentPage,
          limit: this.perPage,
          ...(this.selectedProvider?.name && { name: this.selectedProvider.name })
        };

        const response = await SPService.get(payload);
        
        if (response.result) {
          this.spData = response.data;
          this.providerList = response.data;
          this.totalRows = response.pagination?.total || response.data.length;
          this.totalPages = response.pagination?.totalPages || 
                          Math.ceil(this.totalRows / this.perPage);
          this.currentPage = response.pagination?.currentPage || this.currentPage;
          this.updateQueryParams();
        }
      } catch (error) {
        console.error('Error fetching service providers:', error);
      } finally {
        this.loading = false;
      }
    },



    async getServiceProviderDropDown() {
      // If you need a separate dropdown list without pagination
      try {
        const response = await SPService.get({ limit: 0 }); // 0 could mean all items
        if (response.result) {
          this.providerList = response.data;
        }
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    closeModal() {
      this.spModal = false;
      this.init();
      this.isEdit = false;
    },
    openSPDetail(data) {
      this.spdetail = data;
      this.isEdit = true;
      this.spModal = true;
    },
    addProvider() {
      this.isEdit = false;
      this.spdetail = {};
      this.spModal = true;
    },
    async deleteSPDetail(data) {
      let payload = {
        spid: data._id,
      };
      if (confirm("Are you sure you want to delete")) {
        let response = await SPService.remove(payload);
        let variant = response.result ? "success" : "error";
        this.init();
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
        });
      }
    },
    async changeStatus(data) {
      if (data.status == false) {
        data.status = true;
      } else {
        data.status = false;
      }
      let payload = {
        spid: data._id,
        changeBody: {
          status: data.status,
        },
      };
      let response = await SPService.update(payload);
      let variant = response.result ? "success" : "error";
      this.$toasted.show(data.status=== false ? "Provider Deactivated" : "Provider Activated"  || response.message, {
        theme: "outline",
        position: "bottom-center",
        type: variant,
        duration: 2000,
      });
    },


    async changePage(newPage) {
      this.currentPage = newPage;
      await this.getServiceProvider();
    },

    handlePerPageChange(newPerPage) {
      this.perPage = newPerPage;
      this.currentPage = 1;
      this.getServiceProvider();
    },

    

    async init() {
      this.loading = true;
      this.selectedProvider = [];
      await Promise.all([
        this.getServiceProvider(),
        this.getServiceProviderDropDown()
      ]);
      this.loading = false;
    },

    sentenceCase(str) {
      if (typeof str !== "string") return str;
      return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    },
  },
};
</script>
<style>
.showBreadcrumbs {
  display: none !important;
}
@media screen and (max-width: 991px) {
  .showBreadcrumbs {
    display: block !important;
  }
}
.cust-table-left table th:first-child {
  text-align: left !important;
}
</style>
<style scoped>
@media screen and (max-width: 767px) {
  .custom-font-18,
  .custom-font-24 {
    font-size: 16px !important;
  }
}
</style>
